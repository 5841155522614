import type {SubFlowType} from '@Panels/poster-publishing-options-panel/components/publishing-digital-signage-list/components/digital-signage-compiled-sub-panel/digital-signage-compiled-sub-panel.types';
import type {BasePanel} from '@Components/panel/panel.types';
import type {OrderDetailsForRedux} from '@Panels/poster-publishing-options-panel/components/publishing-embed-in-webpage-panel/publishing-embed-in-webpage-panel.types';
import {MouseEventHandler} from 'react';
import {SocialMediaResponse} from '@Panels/poster-publishing-options-panel/components/publishing-social-media-panel/publishing-social-media-panel.types';
import {Poster} from '@PosterWhiteboard/poster/poster.class';

export enum DisplayType {
  MODAL = 'MODAL',
  DROPDOWN = 'DROPDOWN',
}

export enum ThumbNailType {
  IMAGE = 'IMAGE',
  ICON = 'ICON',
}

export const PUBLISHING_OPTIONS_PANEL_ID = 'PUBLISHING_OPTIONS_PANEL_ID';
export const ANALYTICS_ACTION_FOR_DOWNLOAD = 'ChooseDownloadType';
export const ANALYTICS_ACTION_FOR_PUBLISH = 'ChoosePublishType';
export const ANALYTICS_ACTION_FOR_SIGNAGE = 'ChooseSignageType';
export const ANALYTICS_ACTION_FOR_SOCIAL = 'ChooseSocialType';
export const ANALYTICS_ACTION_FOR_SIGNAGE_PUBLISH = 'DigitalSignagePublish';
export const ANALYTICS_ACTION_FOR_SHARE_HOSTED_LINK_PUBLISH = 'ShareHostedLinkPublished';
export const ANALYTICS_ACTION_FOR_SHARE_HOSTED_LINK_UNPUBLISH = 'ShareHostedLinkUnpublished';

export enum SelectionType {
  DEFAULT_DISPLAY = 'DEFAULT_DISPLAY',
  DIGITAL_SIGNAGE_DISPLAY = 'DIGITAL_SIGNAGE_DISPLAY',
  PUBLISH_WEBPAGE_DISPLAY = 'PUBLISH_WEBPAGE_DISPLAY',
  EMBED_WEBPAGE_DISPLAY = 'EMBED_WEBPAGE_DISPLAY',
  SOCIAL_MEDIA_DISPLAY = 'SOCIAL_MEDIA_DISPLAY',
  SUB_DISPLAY = 'SUB_DISPLAY',
  SHARE_TO_APP = 'SHARE_TO_APP',
}

export interface PosterPublishingOptionsPanelProps extends BasePanel {
  isPosterMaker: number;
  preselectPaidProduct: boolean;
  isPremium: number;
  posterType?: string;
  idGalleryTemplate?: string;
  idTemplate?: string;
  uc: number;
  isVideo: number;
  hasVideoItem: number;
  isExpensiveVideo: number;
  idPosterHash: string;
  isTransparent: number;
  hasClickableLink: number;
  pw?: number;
  ph?: number;
  ptw?: number;
  pth?: number;
  ptu?: string;
  panelDetails: PosterPublishingOptionsPanelSubProps;
}

interface PosterPublishingOptionsPanelSubProps {
  hashedId: string;
  displayType: DisplayType;
  onClose?: () => void | MouseEventHandler;
}

export interface FreebieData {
  id: string;
  name: string;
  description: string;
  productPrice: number | null;
  price: string | null;
  status: string;
  isFree: boolean;
  isVideo: boolean;
  format: string;
  hasWatermark: boolean;
  maxDimension: string;
  height: number;
  width: number;
  needsPurchase: boolean;
}

export interface AJAXResponseProductData {
  id: string;
  name: string;
  description: string;
  rawPrice: number;
  price: string;
  productPrice: number;
  daysFree: number;
  premiumContentAdded: boolean;
  retriesLeft: number;
  creditsLeft: number;
  productCreditPrice: number;
  extraInfo: any[];
  galleryTemplateHash: string | null;
  status: string;
  orderId: string | null;
  purchaseId: string | null;
  encryptedPurchaseId: string;
  isWebDownload: boolean;
  orderFileURLs: any[];
  downloadUrl: string;
  downloadFileUrl: number;
  isFree: boolean;
  hashedId: string;
  posterId: string;
  isVideo: boolean;
  remainingCredits: number;
  format: string;
  maxDimension: string;
  height: number;
  width: number;
  hasWatermark: boolean;
  hasClickableLinks: boolean;
  isLargeSize: boolean;
  posterRenderId: string;
  iconClassName: string;
  needsPurchase: boolean;
  posterName: string;
  mimeType: string;
}

export interface AJAXResponseProductDataComplete {
  freebieProduct: AJAXResponseProductData;
  productData: AJAXResponseProductData[];
  socialMediaLinks: SocialMediaResponse;
}

export enum ProductType {
  PRINT,
  POSTER,
  FREEBIE,
}

export interface PosterPublishingOptionsReduxState {
  thumbnailType: ThumbNailType;
  displayType?: DisplayType;
  selectionType: SelectionType;
  subFlowType: SubFlowType;
  orderDetails: OrderDetailsForRedux;
  freebieProductData?: AJAXResponseProductData;
  premiumProductDataForPrint?: AJAXResponseProductData;
  premiumProductDataForPoster?: AJAXResponseProductData;
  productType?: ProductType;
  showLoader: boolean;
  showError: boolean;
  errorMessage: string;
  socialMediaLinks: SocialMediaResponse;
}

export const initialOrderDetails: OrderDetailsForRedux = {
  progressPercentage: 5,
  estimatedTimeInSeconds: '',
  timeElapsedInSeconds: 0,
  error: '',
};
